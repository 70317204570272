import config from 'config'
import { deleteCookie, getCookie } from 'helpers'
import { responseError } from 'helpers'

const baseUrl = config.baseUrl

const credentials = {
  credentials: 'include'
}

let currentToken = null

function revokeCredentials() {
  deleteCookie('accessToken')
  deleteCookie('sessionCookie')
  // eslint-disable-next-line no-restricted-globals
  return location.reload()
}

function validateCredentials() {
  if (currentToken !== getCookie('accessToken')) {
    revokeCredentials()
  }
}

// example: { key: value, foo: bar } is traduced in ?key=value&foo=bar
export function getQueryString(options) {
  let queryString = ''
  if (options !== undefined && typeof options === 'object') {
    queryString += '?'

    const optEntries = Object.entries(options)

    let firstEl = true
    for (let [key, value] of optEntries) {
      if (typeof value === 'object') {
        value = JSON.stringify(value)
      }

      value = encodeURIComponent(value)
      queryString += firstEl ? `${key}=${value}` : `&${key}=${value}`

      firstEl = false
    }
  }

  return queryString
}

export async function initialize(token) {
  const res = await fetch(`${baseUrl}/auth`, {
    method: 'POST',
    ...credentials,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    },
    body: JSON.stringify({
      from: 'client',
      version: config.version,
    })
  })

  const parsedRes = await res.json()

  currentToken = parsedRes.accessToken

  return parsedRes
}

export async function getAll(service, options) {
  try {
    validateCredentials()

    const queryString = getQueryString(options)
    const res = await fetch(`${baseUrl}/${service}${queryString}`, {
      ...credentials,
      headers: {
        Authorization: getCookie('accessToken')
      }
    })

    if (res.status === 401 && true) {
      return revokeCredentials()
    }

    return res.json()
  } catch (err) {
    console.error('ApiService', 'Error de conexión al hacer GET')
    console.info(service, options)
    console.info(err)
    throw err
  }
}

export async function getOne(service, id, options) {
  try {
    validateCredentials()

    const queryString = getQueryString(options)
    const res = await fetch(`${baseUrl}/${service}/${id}${queryString}`, {
      ...credentials,
      headers: {
        Authorization: getCookie('accessToken')
      }
    })

    if (res.status === 401) {
      return revokeCredentials()
    }

    if (res.status === 404) {
      return {}
    }

    return res.json()
  } catch (err) {
    console.error('ApiService', 'Error de conexión al hacer GET')
    console.info(service, id, options)
    console.info(err)
    throw err
  }
}


export async function post(service, data, files) {
  try {
    validateCredentials()

    const res = await fetch(`${baseUrl}/${service}`, {
      method: 'POST',
      ...credentials,
      headers: {
        Authorization: getCookie('accessToken'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })

    if (res.status === 401 && true) {
      return revokeCredentials()
    }

    return res.json()
  } catch (err) {
    console.error('ApiService', 'Error de conexión al hacer POST')
    console.info(service, data)
    console.info(err)
    throw err
  }
}


export async function deleteOne(service, id) {
  try {
    validateCredentials()

    const res = await fetch(`${baseUrl}/${service}/${id}`, {
      method: 'DELETE',
      ...credentials,
      headers: {
        Authorization: getCookie('accessToken')
      }
    })

    if (res.status === 401 && true) {
      return revokeCredentials()
    }

    return res
  } catch (err) {
    console.error('ApiService', 'Error de conexión al hacer DELETE')
    console.info(service, id)
    console.info(err)
    throw err
  }
}

export async function updateWithFiles(service, id, data, files) {
  try {
    const formData = new FormData()

    for (const name in files) {
      formData.append(name, files[name]);
    }

    formData.append('metadata', JSON.stringify(data))

    const res = await fetch(`${baseUrl}/${service}/${id}`, {
      method: 'PUT',
      ...credentials,
      headers: {
        Authorization: getCookie('accessToken')
      },
      body: formData
    })

    if (res.status === 400) return responseError()

    if (res.status === 401 && true) {
      return revokeCredentials()
    }

    return res.json()
  } catch (err) {
    console.error('ApiService', 'Error de conexión al hacer PUT con archivos')
    console.info(service, id, data, files)
    console.info(err)
    throw err
  }
}

export async function update(service, id, data, files) {
  try {
    validateCredentials()

    // if we have files to upload
    if (files && Object.keys(files).length) {
      return updateWithFiles(service, id, data, files)
    }
    // normal update flow
    const res = await fetch(`${baseUrl}/${service}/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: getCookie('accessToken'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })

    if (res.status === 400) return responseError()

    if (res.status === 401 && true) {
      return revokeCredentials()
    }

    return res.json()
  } catch (err) {
    console.error('ApiService', 'Error de conexión al hacer PUT')
    console.info(service, id, data, files)
    console.info(err)
    throw err
  }
}

export async function exportDetails(service, id, data) {
  try {
    validateCredentials()

    // normal update flow
    const res = await fetch(`${baseUrl}/${service}/${id}/export-details`, {
      method: 'POST',
      headers: {
        Authorization: getCookie('accessToken'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })

    if (res.status === 400) return responseError()
    if (res.status === 401) return revokeCredentials()

    const blob = await res.blob();

    const link = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute('download', 'detalles_de_la_factura_' + id + '.xlsx');
    document.body.appendChild(link);
    link.click();

    link.remove();
    window.URL.revokeObjectURL(url);
  } catch (err) {
    console.error('ApiService', 'Error de conexión al hacer PUT')
    console.info(service, id, data)
    console.info(err)
    throw err
  }
}
