import React, { useState } from "react"
import { Table, Card } from "reactstrap"
import { Link } from "react-router-dom"

import status from 'services/status'
import Loader from "components/TableLoader"

import './Table.sass'

function ContentTable (props) {

  const { items, isLoading } = props
  
  const [activeRow, setActiveRow] = useState(-1)

  const columns = [
    {
      label: 'N° solicitud',
      name: 'solicitud',
      sort: true,
      value: (row) => row.solicitud
    },
    {
      label: 'N° afiliado',
      name: 'afiliado',
      sort: true,
      value: (row) => row.afiliado
    },
    {
      label: 'Cantidad de Sesiones',
      name: 'cant_sesiones',
      sort: true,
      value: (row) => row.cant_sesiones
    },
    {
      label: 'Monto',
      name: 'monto',
      sort: true,
      value: (row) => row.monto
    }
  ]

  return (
    <>
      <Table className='align-items-center table-flush table--desktop' responsive={ false }>
        <thead className="thead-primary table--head">
          <tr className='table--head__tr'>
            {
              // Cabecera
              columns.map((column) => {
                return <th key={column.name} scope='col' className={column.classes ?? ''}>{ column.label }</th>
              })
            }
          </tr>
        </thead>
        <tbody className='table--body'>
        {
          // Datos
          !isLoading
          ? Array.isArray(items) && items.length
            ? items.map(row =>
              <tr key={row.id}>
                { columns.map(column => <td key={ column.name } className={ column.classes ?? '' } >{ column.value(row) }</td>) }
              </tr>
            )
            : <tr>
                <td colSpan={ columns.length } className='text-center'>
                  <span>No se encontraron solicitudes</span>
                </td>
              </tr>
          : <tr>
              <td colSpan={ columns.length } className='text-center'>
                <Loader />
              </td>
            </tr>
        }
        </tbody>
      </Table>
    </>
  )
}

export default ContentTable
