import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Card, CardHeader, CardTitle, CardBody, Row, Col, Button, Form } from 'reactstrap'
import { getOne, exportDetails } from '../../services/ApiService'
import { useAlertPlus } from '../../hooks/useAlertPlus'
import AlertModal from '../../components/AlertModal'
import config from '../../config'
import BillDetailsTable from 'components/Bills/BillDetailsTable'

import './Bill.scss'

function Bill () {
  const { id } = useParams()
  const [bill, setBill] = useState({})
  const [billDetails, setBillDetails] = useState({})
  const [alertShow, alertMessage, alertCallback, setAlert] = useAlertPlus('')
  const history = useHistory()
  const formElement = useRef(null)
  const [ isLoading, setLoading ] = useState(true)

  useEffect(() => {
    getOne('submission-orders', id)
    .then(result => {
      if(!result) {
        setLoading(false)
        setAlert('No se pudo encontrar la facturación solicitada.', () => history.goBack())
        return
      }

      if(result.message && result.message === '404 Not Found') {
        setLoading(false)
        setAlert('No se pudo encontrar la facturación solicitada.', () => history.goBack())
        return
      }

      const { bill, billDetails } = result

      setBill(bill)
      setBillDetails(billDetails)
      setLoading(false)
    })
    .catch(error => {
      console.error(error)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const exportBillDetails = async () => {
    try {
      setLoading(true)

      // Enviamos la petición
      const res = await exportDetails('submission-orders', id, billDetails)

      if (res.error) {
        setAlert('Ha ocurrido un error exportar, por favor intente nuevamente.')
        setLoading(false)
        return
      }

      setLoading(false)
      return
    } catch(err) {
      console.log(err)
      setLoading(false)
    }
  }

  return (
    <div className='content'>
      <Row>
        <Col md='12'>
          <Card className='card-user'>
            <CardHeader>
              <Row>
                <Col md='8'>
                  <CardTitle tag='h5'>Solicitud #{ id } - { bill ? bill.status : 'Borrador' }</CardTitle>
                </Col>
                <Col md='4' className='back-button-container'>
                  <Button className='btn-round' color='primary' onClick={() => history.goBack()}>
                    Volver
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Form innerRef={ formElement }>
                <Row className="mb-2">
                  <Col md='2' sm='12'><strong>Obra Social</strong></Col>
                  <Col md='10' sm='12'>{ bill.ooss?.nombre } ({ bill.ooss?.cuit })</Col>
                </Row>
                <Row className="mb-2">
                  <Col md='2' sm='12'><strong>Tipo de Factura</strong></Col>
                  <Col md='10' sm='12'>{ bill.billType }</Col>
                </Row>
                <Row className="mb-2">
                  <Col md='2' sm='12'><strong>Concepto</strong></Col>
                  <Col md='10' sm='12'>{ bill.concept }</Col>
                </Row>
                <Row className="mb-2">
                  <Col md='2' sm='12'><strong>Periodo</strong></Col>
                  <Col md='10' sm='12'>{ bill.monthPeriod }/{ bill.yearPeriod }</Col>
                </Row>
                <Row className="mb-2">
                  <Col md='2' sm='12'><strong>Fecha de Solicitud</strong></Col>
                  <Col md='10' sm='12'>{ new Date(bill.createdAt).toLocaleDateString('es-AR', { dateStyle: 'short' }) }</Col>
                </Row>
                <Row className="mb-2">
                  <Col md='2' sm='12'><strong>Monto</strong></Col>
                  <Col md='10' sm='12'>$ { bill.amount }</Col>
                </Row>
                <Row>
                  <div className='update ml-auto mr-4'>
                    <Button className='btn-round ml-4' color='success' title='Descargar Excel' onClick={ () => exportBillDetails() } disabled={ isLoading }>
                      Descargar Excel
                    </Button>
                  </div>
                </Row>
                <Row className="mt-4 m-2">
                  <BillDetailsTable
                    isLoading={isLoading}
                    items={billDetails}
                  />
                </Row>
                {
                  bill.attachs && bill.attachs.map(({ type, url }, idx) => {
                    return (
                      <Row className="mt-4 mb-2" key={`attach_${idx}`}>
                        <Col md='2' sm='12'><strong>{ type === 'factura' ? 'Factura' : 'Nota de Crédito' }</strong></Col>
                        <Col md='10' sm='12'>
                          <Button color="primary" onClick={ () => window.open(`${config.baseUrl}/${url}`, "_blank") }>Ver adjunto</Button>
                        </Col>
                      </Row>
                    )
                  })
                }
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <AlertModal isOpen={alertShow} message={alertMessage} onClose={alertCallback} />
    </div>
  )
}

export default Bill
